.calender_body {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.calender_block {
    max-width: inherit !important;
    width: 30.3% !important;
    margin: 10px 0.5% !important;
    background: #f2f8fa;
    padding: 0.5% !important;
    border: solid 1px #f5e4c7 !important;
    word-break: break-all;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
    position: relative;
    z-index: 0;
}

.calender_block:hover {
    transform: scale(1.1);
    z-index: 1;
}

.calender_block h2 {
    margin: 0;
    display: block;
    background: #00abff;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    font-size: 18px;
    padding: 8px 5px;
    font-size: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
    word-break: break-word;
}

.calender_block p {
    margin: 0;
    padding: 6px 0;
}

.calender_block p span {
    color: #3f86ce;
}

.calender_block p .innerSpan {
    color: #000;
}

.calender_block .cancelSlotBtnSection {
    margin-top: 10px;
}

.calender_block .slotBookBtn {
    margin: 5px;
}

.calender_block.bookNowModal {
    width: 100% !important;
    background: none;
    box-shadow: none;
    border: none !important;
    word-break: normal;
}

.calender_block.bookNowModal:hover {
    transform: none !important;
}

.formCls .multipleCheckbox .MuiFormControl-root {
    flex: 1 0 100%;
    box-shadow: none;
}

.formCls .eventDetails .MuiFormLabel-root {
    position: relative;
    height: 50px;
    width: auto;
    background: #626fb1;
    text-align: left;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: none;
    text-transform: capitalize;
}

.formCls .eventDetails.sunEditor label {
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.formCls .multipleCheckbox .MuiFormControlLabel-label {
    color: #000;
}

.formCls .multipleCheckbox .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    color: rgb(0 0 0);
    font-weight: 600;
}

.formCls .singleCheckbox .MuiFormControl-root {
    color: #000;
    flex: 1 0 100%;
    box-shadow: none;
}

.formCls .singleCheckbox .MuiInputLabel-root {
    color: #000;
    font-weight: 600;
}

.formCls .singleCheckbox {
    padding: 0 8px !important;
}

.ContentWrapper {
    width: 1780px;
    margin: 20px auto 0;
    display: block;
}

.connectedemail {
    margin: 0;
    background: #00abff;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    font-size: 18px;
    padding: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
}

.connectedemail h2 {
    margin: 0;
    margin-bottom: 15px;
}

.calenderinfo {
    width: 450px;
}

.calenderinfo p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

.calenderinfo p span {
    text-transform: initial!important;
}

.buttonsetToolbar {
    margin: 0;
    background: #c9d2d9;
    font-size: 18px;
    padding: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 20px;
}

.buttonsetToolbar button {
    margin: 5px
}

.buttonsetToolbar button a,
.buttonsetToolbar a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.buttonsetToolbar button:hover a,
.buttonsetToolbar a:hover {
    color: #000;
}

.formCls {
    margin-top: 20px;
}

.reactTable .calendarAvailabilityTable {
    width: 100%;
}

.reactTable .calendarAvailabilityTable table thead tr th:last-child,
.reactTable .calendarAvailabilityTable table tbody tr td:last-child {
    width: 160px;
    text-align: center;
}


/*--------amitava 5/17/2022-----------*/

.addemailbox {
    position: relative;
}

.addemailbox input {
    margin: 5px 0;
}

.addemail {
    padding-right: 10%!important;
}

.addemailBTN {
    width: 10%;
    height: 36px;
    position: absolute;
    right: 0;
    bottom: 5px;
    border: none;
    background: #08507f;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.modalSUBbtn_wrapper {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.modalSUBbtn_wrapper .modalSUBbtn {
    margin: 2px;
    color: #fff;
    background-color: #1976d2!important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.banner_IMG {
    margin: 0 auto;
}

.banner_IMG img {
    width: 100%;
    display: block;
}

.form_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #f3f1f1;
    border-radius: 6px;
    border: solid 1px #c9d2d9;
    box-shadow: 0 0 2px #c9d2d9;
    padding: 15px;
    margin: 14px 0 0 0;
    align-items: center;
}

.form_wrapper .subBTN Button {
    height: 46px;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-top: 32px;
    line-height: 54px;
    display: block;
    padding: 0;
    border-radius: 100px;
    width: 46px;
    min-width: inherit;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    margin-left: 15px;
}

.subBTN .MuiSvgIcon-root {
    width: 40px;
    height: 48px;
}

.form_wrapper .form_group {
    width: 400px;
    margin: 5px 10px;
}

.form_wrapper .form_group .MuiOutlinedInput-root {
    width: 100%;
}

.form_wrapper .form_group label {
    display: block;
    margin-bottom: 10px;
    color: #236bb0;
}

.form_wrapper .form_group label.MuiInputLabel-formControl {
    color: #000;
}

.form_wrapper .MuiInputBase-input {
    background: #fff;
}

.submitbtnsection {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

.submitbtnsection Button[type="submit"] {
    display: inline-block;
}

.BioEnergetics_banner1 {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1BG.jpg);
  background-position: center top; background-size: 100% 100%;
  background-repeat: no-repeat;    */
    background: rgb(17, 132, 192);
    background: linear-gradient(90deg, rgba(17, 132, 192, 1) 0%, rgba(99, 50, 222, 1) 100%);
    border-radius: 15px;
}

.banner_textblock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 27px;
    min-height: 238px;
}

.banner_textblock .banner_textblock_logo {
    width: 20%;
}

.banner_textblock .banner_textblock_logo span {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    padding: 10px 14px 5px 10px;
}

.banner_textblock .banner_textblock_logo span.logo_content {
    background: none;
    text-transform: capitalize;
    color: #fff;
    font-size: 29px;
    text-shadow: 0 0 2px #465a34;
    font-weight: bold;
}

.banner_textblock .banner_textblock_logo span img {
    width: 100%;
}

.banner_textblock .banner_textblock_logo.betoBannerLogo {
    width: 100% !important;
}

.banner_textblock h2 {
    margin: 0;
    padding: 0;
    font-size: 45px;
    color: #ffffff;
    width: 75%;
    text-shadow: 0 0 2px #465a34;
    text-transform: capitalize;
}

.banner_textblock h2 p {
    display: block;
    font-size: 26px;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-top: 12px;
    font-weight: normal;
    text-shadow: none;
}

.BioEnergetics_banner1 .banner_textblock p {
    color: #ffffff;
}

.PECE_banner1 {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1BG.jpg);
  background-position: center top; background-size: 100% 100%;
  background-repeat: no-repeat;   */
    background: rgb(49, 216, 103);
    background: linear-gradient(90deg, rgba(49, 216, 103, 1) 0%, rgba(217, 226, 95, 1) 100%);
    border-radius: 15px;
}

.BioEnergetics_banner1_bg {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1_topBG.png), url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1_bottomBG.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    border-radius: 15px;
}

.PECE_banner1_bg {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_topBG.png), url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_bottomBG.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    border-radius: 15px;
}

.canceledAlready.thankyou-container {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape.png), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape2.png);
    background-color: #170036;
    background-position: -85px -158px, 627px 480px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
}

.canceledAlready.thankyou-container .thankyou-block {
    text-align: center;
    line-height: 3px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.canceledAlready.thankyou-container .thankyou-block .content {
    color: white;
    text-align: center;
    line-height: 3px;
    max-width: 800px;
    padding: 0;
}

.canceledAlready.thankyou-container .thankyou-block .content h1 {
    font-size: 36px;
    line-height: 36px;
}

.canceledAlready.thankyou-container .thankyou-block .content p {
    font-size: 22px;
    color: #fff;
    margin: 10px 0px;
}

.modalblock .MuiBox-root {
    border: solid 10px #08507f;
    border-radius: 4px;
    padding: 15px;
}

.modalblock .MuiBox-root .header {
    width: calc( 100% + 2px);
    background: #13507f;
    position: absolute;
    top: -1px;
    text-align: center;
    left: -1px;
    height: 35px;
    font-size: 32px;
    line-height: 30px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.modalblock .MuiBox-root h2 {
    margin: 0;
    padding: 0;
    color: #1b4965;
    font-size: 30px;
    line-height: 30px;
}

.modalblock .MuiBox-root h3 {
    margin: 0;
    padding: 0;
    color: #1b4965;
}

.modalblock .MuiBox-root h4 {
    margin: 0;
    padding: 10px 0;
    color: #000;
    font-size: 18px;
}

.modalblock .MuiBox-root input {
    width: 100%;
    height: 36px;
    background: #f3efef;
    border: solid 1px #d1c0c0;
    box-shadow: none;
    padding: 0px 0px 0px 4px;
    color: #000;
    padding-right: 0px !important;
}

.slotModal.modalblock .MuiBox-root {
    padding-top: 35px;
    position: relative;
}

.modalblock .MuiBox-root h3 {
    text-align: center;
    margin-top: 10px;
}

.bookingModal.modalblock .MuiBox-root {
    padding: 0px !important;
}

.bookingModal.modalblock .modalBox {
    max-height: 82vh;
    overflow-y: scroll;
    border: 0px !important;
    height: 100% !important;
    padding: 15px !important;
}

.bookingModal.modalblock .closeModalBtn,
.deleteModal.modalblock .closeModalBtn,
.custommodal.modalblock .CloseModal {
    position: absolute;
    background: #2076d2!important;
    top: -20px;
    width: 30px;
    color: #fff;
    height: 30px;
    right: -20px;
    min-width: auto;
    border-radius: 50%;
}


/* .deleteModal{
  position: relative;
}
  .deleteModal .modalCloseBtn{
    position: absolute;
  } */


/* ------------ Responsive ----------------- */

@media (max-width:701px) {
    .canceledAlready.thankyou-container .thankyou-block .content {
        max-width: 700px;
        line-height: 20px !important;
    }
}

@media screen and (max-width:500px) {
    .canceledAlready.thankyou-container .thankyou-block .content h1 {
        font-size: 30px;
        line-height: 30px;
    }
    .canceledAlready.thankyou-container .thankyou-block .content p {
        font-size: 17px;
        margin-bottom: 0px;
    }
}


/* ---------- Load More ------------- */

.loadMoreSection {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
}


/* ---------- Cancel Slot------------ */

.cancelSlotBtnSection {
    display: flex;
    justify-content: center;
}


/*------------------------------------*/

@media screen and (max-width: 1800px) {
    .ContentWrapper {
        width: 96%;
    }
}

@media screen and (max-width: 1460px) {
    .banner_textblock .banner_textblock_logo {
        width: 25%;
    }
    .banner_textblock h2 {
        width: 70%;
    }
}

@media screen and (max-width: 1360px) {
    .banner_textblock .banner_textblock_logo {
        width: 28%;
    }
    .banner_textblock h2 {
        width: 67%;
    }
}

@media screen and (max-width: 1199px) {
    .BioEnergetics_banner1_bg {
        background-image: none;
    }
    .PECE_banner1_bg {
        background-image: none;
    }
    .banner_textblock {
        padding: 15px;
    }
    .banner_textblock {
        display: block;
        text-align: center;
    }
    .banner_textblock .banner_textblock_logo {
        width: auto;
        display: inline-block;
        margin-bottom: 15px;
    }
    .banner_textblock h2 {
        width: auto;
        text-align: center;
    }
    .banner_textblock .banner_textblock_logo span {
        width: auto;
    }
    .banner_textblock .banner_textblock_logo span img {
        width: auto;
        max-width: 90%;
    }
    .banner_textblock h2 {
        font-size: 30px;
    }
    .banner_textblock h2 p {
        font-size: 20px;
    }
    .calender_block {
        width: 49% !important;
    }
    .form_wrapper .subBTN {
        clear: both;
        overflow: hidden;
        margin-top: 10px;
        display: block;
        width: 100%;
        text-align: center;
    }
    .form_wrapper .subBTN Button {
        margin-top: 0px;
        display: inline-block;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 991px) {
    .formCls .formInline .formGroup .countDiv__column--col6 {
        flex: 1 0 98%!important;
    }
}

@media screen and (max-width: 991px) {
    .formCls .formInline .formGroup .countDiv__column--col5 {
        flex: 1 0 99%!important;
    }
}

@media screen and (max-width: 767px) {
    .calender_block {
        width: 99% !important;
    }
    .connectedemail {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 540px) {
    .buttonsetToolbar {
        flex-wrap: wrap;
    }
}