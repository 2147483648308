* {
  box-sizing: border-box;
}
:root {
  --red: #cc0006;
  --white: #fff;
  --green: #16a085;
  --orange: #eb9532;
  --purple: #7e3661;
  --pink: #ea53c2;
  --blue: #25aceb;
  --yellow: #e3db00;
  --black: #000;
  --links: #1dbce9;
  --gray: #36404a;
  --site-color: #ff8a00;
  --site-color2: #fed245;
  --site-color3: #c39c8a;
  --site-h1-gradient: linear-gradient(
    90deg,
    var(--site-color3) 25%,
    var(--site-color4) 50%,
    var(--site-color3) 80%
  );
  --main-font: Roboto, sans-serif;
  --main-font: Roboto, sans-serif;
  --main-thin: 100;
  --main-extralight: 100;
  --main-light: 100;
  --main-regular: 400;
  --main-medium: 500;
  --main-semibold: 600;
  --main-bold: 700;
  --main-extrabold: 800;
  --main-black: 900;
  --base-font-size: 24px;
}
@viewport {
  width: device-width;
  zoom: 1.1;
  min-zoom: 0.4;
  max-zoom: 2;
  user-zoom: fixed;
}
a {
  color: var(--links);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.clear {
  clear: both;
  overflow: hidden;
}
img {
  border: 0;
  outline: 0;
  max-width: 100%;
}
a img {
  outline: none;
}
a {
  outline: none;
  cursor: pointer;
}
a:active,
a:focus {
  outline: none;
}
:active,
:focus {
  outline: none;
  -moz-outline-style: none;
}
:link:focus,
:visited:focus {
  outline: none;
}
html {
  overflow-y: scroll;
  overflow-x: hidden;
}
html,
body {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: var(--main-font);
  counter-reset: location;
  font-size: var(--base-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #dbdbdb9e;
}
button {
  word-wrap: break-word;
  white-space: unset;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
mat-label {
  word-wrap: break-word;
  white-space: unset;
  overflow-wrap: break-word;
}
p:empty {
  display: none;
}
section:empty {
  display: none;
}
code {
  font-family: var(--main-font);
}
p {
  font-family: var(--main-font);
  font-weight: var(--main-Regular);
  font-size: 15.93px;
  line-height: normal;
  color: var(--black);
  margin-bottom: 25px;
  position: relative;
  z-index: 4;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
p:last-child {
  margin-bottom: 0;
}
.error {
  color: #f44336;
}
.p30TB {
  padding: 30px 0 !important;
}
.noPadding .p30TB {
  padding: 0 !important;
}
.mr20 {
  margin-right: 10px;
}

/* .container{align-items:center;justify-content:center}
header{position: relative;top: 0;z-index: 9;}
body{align-items:center;justify-content:center;}
.mainContent{position: relative;padding-bottom: 0;}
.containerView{align-items:center;} */

/*****grid css***********/
.countDiv {
  height: auto;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
  background: transparent;
  box-shadow: none;
}
.countDiv.mat-card {
  padding: 0;
  background: transparent;
  box-shadow: none;
}
.countDiv .mat-card {
  position: relative;
  overflow: inherit;
  margin: 0;
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.countDiv__column4,
.countDiv__column3,
.countDiv__column2,
.countDiv__column1,
.countDiv__column {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  box-shadow: none;
}
.countDiv__column--col1 {
  flex: 1 0 6.33333333% !important;
}
.countDiv__column--col2 {
  flex: 1 0 14.66666667% !important;
}
.countDiv__column--col3 {
  flex: 1 0 23% !important;
}
.countDiv__column--col4 {
  flex: 1 0 31.33333333% !important;
}
.countDiv__column--col5 {
  flex: 1 0 39.66666667% !important;
}
.countDiv__column--col6 {
  flex: 1 0 48% !important;
}
.countDiv__column--col7 {
  flex: 1 0 56.33333333% !important;
}
.countDiv__column--col8 {
  flex: 1 0 64.66666667% !important;
}
.countDiv__column--col9 {
  flex: 1 0 73% !important;
}
.countDiv__column--col10 {
  flex: 1 0 81.33333333% !important;
}
.countDiv__column--col11 {
  flex: 1 0 89.66666667% !important;
}
.countDiv__column--col12 {
  flex: 1 0 98% !important;
}

/***** Progress-Bar ******/
.MuiLinearProgress-root {
  display: block;
  overflow: hidden;
  position: absolute;
  transition: opacity 250ms linear;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
}
.MuiLinearProgress-root.css-eglki6-MuiLinearProgress-root {
  background-color: #e40fff;
}
.MuiLinearProgress-root .MuiLinearProgress-bar1Indeterminate {
  background-color: #189aff;
}
.MuiLinearProgress-root .MuiLinearProgress-bar2Indeterminate {
  background-color: #b63cf6;
}
.layoutTopProgressBar {
  position: absolute !important;
  top: 0;
  left: 0;
}
.css-eglki6-MuiLinearProgress-root {
  padding: 0 !important;
}

/***** Login Css ******/
.loginFormBlock {
  padding: 20px 20px 30px;
  border-radius: 20px;
  flex: 1 1;
  background-color: var(--white);
  width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
.loginFormBlock .buttonLists button {
  margin-top: 0;
}
.logoText {
  text-align: center;
  text-transform: capitalize;
  font-size: 36px;
  margin: 0;
  font-weight: 500;
  text-shadow: 2px 1px 2px #00000078;
  color: var(--white);
}
.mainContentWrapper {
  min-height: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
}
.loginFormLogo {
  text-align: left;
  max-width: 100%;
  margin: 0 auto 40px;
}
.loginFormLogo img {
  width: 100%;
}
.loginFormLogo h1 {
  font-size: 24px;
  color: #fff;
}
.loginFormView {
  flex: 1;
}
.loginFormInputBox {
  margin: 0;
  position: relative;
  margin-bottom: 24px;
}
.loginFormInputBox label {
  position: absolute;
  height: 50px;
  width: 100px;
  background: #626fb1;
  text-align: left;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.loginFormTextInput {
  height: 50px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #f0f0f0;
  padding-left: 104px;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  border: 1px solid #9a9a9a;
}
.buttonLists {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 0;
}
.loginButton {
  cursor: pointer;
  background: linear-gradient(90deg, #4ab800 50%, #8cd700 100%);
  border-radius: 50px;
  padding: 14px 20px 10px;
  display: block;
  text-decoration: none;
  border: 1px solid #a7a7a7;
  width: auto;
  margin: 0px auto;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  font-size: 24px;
  color: var(--white);
  text-transform: uppercase;
}
.logoFormText {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  font-size: 36px;
  color: #000000;
  text-transform: uppercase;
}
.linkText {
  color: #000;
  text-align: right;
  display: block;
  font-size: 18px;
  margin-top: 0;
  text-decoration: underline;
  position: absolute;
  bottom: 50px;
  right: 30px;
}
.logoFormNewUserText {
  color: #884a00;
  text-align: center;
  display: block;
  font-size: 24px;
  margin-top: 50px;
  font-weight: 400;
}
.outLineButton {
  cursor: pointer;
  background: #ffb400e0;
  border-radius: 50px;
  padding: 13px 20px 10px;
  display: block;
  text-decoration: none;
  border: 1px solid #a7a7a7;
  width: 124px;
  margin: 0px auto;
  margin-top: 0;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  font-size: 20px;
  color: var(--black);
  text-transform: uppercase;
}
.loginFormBlock form {
  max-width: 96%;
  margin: 0 auto;
}
.loginFormdiv {
  position: relative;
  z-index: 9999;
  padding: 0 auto 40px;
}
/* .forgetBlock .linkText{text-align:center;margin-top:20px;position:inherit;bottom:0;right:0} */

/* .loginFormBlock{
  width: auto;
} */

/**************Background Css***********************/
.bgShape {
  background-color: #ffa101;
  border-radius: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  height: 45%;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 99;
  left: 0;
}
.bgShapeTwo {
  background-color: #ff8e01;
  border-radius: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  height: 50%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
}
/**************Animation Css***********************/

.hero {
  position: absolute !important;
  z-index: 999 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #703d00;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 12s ease-in forwards infinite;
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #703d00;
}
.cube:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@-webkit-keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

@media screen and (max-width: 640) {
  .loginFormBlock {
    max-width: 90%;
  }
}
@media screen and (max-width: 500) {
  .loginFormBlock form {
    max-width: 98%;
  }
  .loginFormLogo {
    max-width: 90%;
  }
}
/***********Start notfound************/
#notfound {
  position: relative;
  height: 100vh;
  margin: 0;
  border-radius: 0;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
}
.notfound .notfound-404 {
  position: relative;
  height: auto;
}
.notfound .notfound-404 h1 {
  font-family: var(--main-font);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0;
  color: #262626;
  text-transform: uppercase;
}
.notfound .notfound-404 h1 > span {
  color: #834b06;
}
.notfound h2 {
  font-family: var(--main-font);
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 90px;
  margin-bottom: 24px;
}
.notfound button {
  border-bottom: 0;
  background: #f55;
  display: block;
  color: #021e2e;
  border-radius: 5px;
  font-family: var(--font-family-base);
  font-weight: var(--main-medium);
  padding: 10px 15px;
  font-size: 16px;
  text-transform: uppercase;
  height: auto;
  margin: 0 auto;
}
/***********End notfound************/

.pageWrapper {
  max-width: 1800px;
  margin: 0 auto;
}
.sectionBlock {
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.blockInnerWrapper {
  background: #c9d5df;
  background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
  padding: 10px 20px;
  border-radius: 15px;
  flex: 1 0 auto;
}

/***********Start TopMenu************/
.pageMain {
  min-height: 100vh;
}
/* .mainContent {padding: 0;height: 100vh;overflow-x: hidden;} */
/* .mainContent {padding: 0;height: 100%;background: #000000;background: linear-gradient(88deg, #000000 0, #383a3e 50%, #000000 100%);overflow-x: hidden;} */
.topMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  background: #00abff;
  background: linear-gradient(180deg, #00abff 0%, #295fa1 100%);
  border-radius: 100px !important;
  padding: 5px 30px 15px !important;
  box-shadow: 0 0 5px #295fa1 !important;
  position: relative;
  z-index: 9;
}
.topLogo .loginFormLogo {
  margin: 0;
}
.topRightMenu {
  text-align: right;
}
.topMenuLists button {
  cursor: pointer;
  font-weight: var(--main-medium);
  font-size: 20px;
  color: #000;
  text-shadow: 0 0 5px rgb(0 0 0 / 20%);
  padding: 5px 30px;
  border-radius: 50px;
  text-transform: capitalize;
}
.topMenuLists button:hover {
  color: var(--white);
  background: #020101;
  background: linear-gradient(180deg, #5d5c5b 0, #020101 100%);
}
.topMenuLists button a {
  color: #000;
}
.topMenuLists button:hover a {
  color: var(--white);
}
.topMenuListsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topRightMenu .goToHome {
  color: #081023;
  background: #c9d5df;
  background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
  cursor: pointer;
  font-weight: var(--main-medium);
  font-size: 16px;
  text-shadow: 0 0 5px rgb(0 0 0 / 20%);
  padding: 5px 30px;
  border-radius: 100px;
  box-shadow: 0px 0px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  border: 1px solid var(--white);
}
/* .topRightMenu{} */
.topRightMenu .myAccount {
  min-width: inherit;
  cursor: pointer;
}
.topRightMenu .myAccount .account_circle {
  background: #5d5c5b;
  background: linear-gradient(180deg, #5d5c5b 0, #020101 100%);
  border-radius: 100px;
  padding: 3px;
  width: 40px;
  height: 40px;
  min-width: inherit;
  margin-left: 10px;
  font-size: 34px;
  color: var(--white);
}
.topRightMenu .myAccount:hover .account_circle {
  background: #020101;
  background: linear-gradient(180deg, #020101 0, #5d5c5b 100%);
}
.topRightMenu .myAccount .expand_more {
  background: var(--white);
  border-radius: 100px;
  padding: 0px;
  width: 24px;
  height: 24px;
  min-width: inherit;
  margin-left: 4px;
  color: #f57439;
  font-size: 24px;
  box-shadow: 0px 0px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
}
.topRightMenu .myAccount:hover .expand_more {
  background: var(--white);
}
.navbarToggle {
  display: none;
}
/***********End TopMenu************/

/***********Form Css Start************/
/* .headerTxt{margin: 0;padding: 0;} */
h2.headerTxt {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  font-size: 24px;
  justify-content: center;
}
.formCls {
  margin: 0;
  padding: 8px;
  border-radius: 20px;
  background: #f0f0f0;
  box-shadow: 0 0 5px #626fb1 !important;
}
.formCls .formInline {
  margin: 0;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.formCls .formInline .formGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  padding: 8px;
  color: #000;
  font-size: 20px;
}
.formCls .MuiFormControl-root {
  padding: 0;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
}
.formCls {
  margin: 0;
  padding: 10px;
  border-radius: 20px;
  background: #c9d5df;
  background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 0 5px #b1b1b1 !important;
}
.formCls form {
  margin: 0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.formCls .MuiFormControl-root {
  padding: 0;
  flex: 1 0 32.33%;
  margin: 0;
}
.formCls .MuiFormControl-root .MuiInputLabel-root {
  position: relative;
  height: 50px;
  width: auto;
  background: #626fb1;
  text-align: left;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-transform: none;
  transform: none;
  text-transform: capitalize;
}
.formCls .MuiFormControl-root .MuiOutlinedInput-root,
.formCls .MuiFormControl-root textarea {
  height: 50px !important;
  font-size: 14px;
  border-radius: 5px;
  background-color: #f0f0f0 !important;
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid #9a9a9a;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 1;
}
.formCls .formInline .formGroup:last-of-type {
  margin-bottom: 10px !important;
}

Button[type="submit"],
.formCls.otherStateCls Button[type="button"] {
  cursor: pointer;
  background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
  border-radius: 50px;
  padding: 8px 30px 4px;
  display: block;
  text-decoration: none;
  width: auto;
  margin: 0px;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  font-size: 24px;
  color: var(--white);
  text-transform: uppercase;
  margin-top: 15px;
}
.formCls.otherStateCls {
  background: transparent;
  padding: 0;
  border-radius: 0;
  box-shadow: none !important;
}
.formCls.otherStateCls Button[type="button"] {
  margin-top: 0;
  margin-left: 20px;
}
.formCls .css-viou3o-MuiAutocomplete-root {
  width: 100%;
}
.formCls .formInline .formGroup.fullWidth {
  width: 100%;
}
.formActionBtn {
  justify-content: center;
  margin-top: 20px !important;
}
.formCls button {
  margin: 0 5px;
}
.formCls .MuiFormControl-root textarea {
  padding: 16.5px 14px;
}
.formCls .switchCls {
  display: flex;
  flex-direction: row-reverse;
}
/* .formCls .formInline .formGroup .css-1nrlq1o-MuiFormControl-root{flex-wrap: wrap;flex-direction: column;} */
.formCls .formInline .formGroup .heading {
  font-weight: var(--main-semibold);
}

/* MuiDrawer */
.MuiDrawer-root .MuiBackdrop-root {
  background-color: transparent;
}
.MuiPaper-root .formCls {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.MuiModal-root .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}
.css-a5rdam-MuiGrid-root > .MuiGrid-item {
  padding-top: 20px;
}
.css-a5rdam-MuiGrid-root > .MuiGrid-item {
  padding-left: 20px;
}
.MuiModal-root .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  background: #c9d5df;
  background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
  padding: 10px 20px;
  border-radius: 0;
}
.MuiModal-root .MuiPaper-root .formCls {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
.MuiModal-root.otherStateCls .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfe8edb3;
  background: linear-gradient(180deg, #dfe8ed73 0, #c9d5df8c 100%);
}
/******************/
.formCls .MuiFormControl-root .MuiInputLabel-root {
  position: relative;
  height: 50px;
  width: auto;
  background: #626fb1;
  text-align: left;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transform: none;
  text-transform: capitalize;
}
/*//////////////// width: 190px;////////////////*/
.formCls.otherStateCls .formInline {
  background-color: #efefef;
  padding: 20px;
  border: 2px solid #c6cbce;
  border-radius: 10px;
  position: relative;
}
.formCls .close {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #ff4c09;
  text-shadow: 0 1px 0 var(--white);
  filter: alpha(opacity=20);
  opacity: 1 !important;
  padding: 0 9px 2px !important;
  border-radius: 100px;
  right: 5px;
  position: absolute;
  top: 5px;
  outline: none !important;
  width: 20px;
  height: 20px;
  padding: 0px !important;
  min-width: 1px;
  cursor: pointer;
}
/***********Form Css End************/

/**************Start Table and Title css*********************/
.headerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.headerBlock .buttonListCls button {
  color: var(--white);
  background: #bd240c;
  background: linear-gradient(180deg, #bd240c 0, #fb6603 100%);
  cursor: pointer;
  font-weight: var(--main-medium);
  font-size: 16px;
  text-shadow: 0 0 5px rgb(0 0 0 / 20%);
  padding: 5px 30px;
  border-radius: 100px;
}
.headerBlock .titleHeader h1 {
  margin: 0;
  padding: 0;
  color: var(--white);
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
}
.headerBlock .buttonListCls {
  display: flex;
  align-items: center;
}
.headerBlock .buttonListCls .searchIcon {
  color: #000;
  font-weight: 600 !important;
  font-size: 19px;
  background: #bd240c;
  background: linear-gradient(180deg, #d7d7d7 0, #878787 100%);
  cursor: pointer;
  font-weight: var(--main-medium);
  text-shadow: 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 100px;
  margin-right: 10px;
  padding: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reactTableWrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
table * {
  white-space: nowrap;
}
.reactTable > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.reactTable > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}
.reactTable {
  width: 100%;
  margin-bottom: 0;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.reactTable > thead {
  vertical-align: bottom;
  color: #081023;
  background: #c9d5df;
  background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
  cursor: pointer;
  font-weight: var(--main-medium);
  font-size: 16px;
  text-shadow: 0 0 5px rgb(0 0 0 / 20%);
  padding: 5px 30px;
  border-radius: 100px;
  box-shadow: 0px 0px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  border: 1px solid var(--white);
  border-radius: 4px;
}
.reactTable tbody,
.reactTable td,
.reactTable tfoot,
.reactTable th,
.reactTable thead,
.reactTable tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.reactTable tbody {
  color: #081023;
  background: #c9d5df;
  background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
  cursor: pointer;
  font-weight: var(--main-medium);
  font-size: 16px;
  padding: 5px 30px;
  border-radius: 100px;
  box-shadow: 0px 0px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.5s;
  border: 1px solid var(--white);
  border-radius: 4px;
}
.reactTable thead tr,
.reactTable thead tr td {
  font-weight: 500;
}
.reactTable th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.reactTable > tbody {
  vertical-align: inherit;
}
.reactTable tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-bottom: 1px solid #b8c4d3 !important;
}
.reactTable > :not(caption) > * > * {
  padding: 15px 12px;
  border-bottom-width: 0;
}
.reactTable td {
  display: table-cell;
  vertical-align: inherit;
  white-space: normal;
  overflow-wrap: anywhere;
}
.reactTable tr:last-child td {
  border-bottom: 0 solid #333 !important;
}
.reactTable tr:nth-child(even) {
  background-color: #ced9e1;
}
.reactTable tr:nth-child(odd) {
  background-color: #c9d5df;
}
.reactTableWrapper {
  position: relative;
}
.MuiSkeleton {
  width: 100%;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ff0000 !important;
}
.reactTableWrapper .reactTable .MuiToolbar-regular {
  background: #7daaca;
}
.reactTableWrapper
  .reactTable
  .css-14covj4-MuiToolbar-root
  .css-1q1u3t4-MuiTableRow-root {
  background: #f3f3f3;
}
.calendarAvailabilityTable .css-1mxz8qt-MuiPaper-root {
  padding: 15px 0;
}
.reactTableWrapper .reactTable .css-1yhpg23-MuiTableCell-root {
  display: table-cell;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.reactTableWrapper .reactTable .css-1yhpg23-MuiTableCell-root button {
  padding: 0px;
  margin: 3px;
}

/* View Modal */
.custommodal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.custommodal h1 {
  color: #2a2716;
  font-size: 25px;
  margin: 0;
  padding: 0 0 8px 0;
  text-align: center;
  font-weight: var(--main-medium);
}

.custommodal .MuiBox-root {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  border: solid 5px #fff;
  width: auto;
  min-width: 600px;
  max-width: 90%;
  position: relative;
  top: 0 !important;
  left: 0 !important;
  text-align: left !important;
  transform: inherit !important;
  max-height: 68%;
  display: flex;
  flex-direction: column;
}
.CloseModal {
  position: absolute !important;
  right: 1px;
  top: 1px;
  background-color: #fff !important;
  padding: 0 !important;
  color: #000 !important;
}

.CloseModal svg {
  width: 28px;
  height: 28px;
}
.modalview_list {
  overflow: hidden;
  max-height: 100%;
  /* overflow-y: scroll; */
}
.modalview_list::-webkit-scrollbar {
  width: 5px;
  background: #fff;
  opacity: 1;
  outline: 1px solid #a3a09f;
  border-radius: 10px;
}

.modalview_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.modalview_list::-webkit-scrollbar-thumb {
  background-color: #e1480a;
  outline: 1px solid #cb3109;
  border-radius: 10px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.modalview_list .modalview_group > div > label {
}
.modalview_list .modalview_group > div > label:first-child {
  color: #7daaca;
  font-size: 18px;
}
.modalview_list .modalview_group > div > label:nth-child(2) {
  color: #000;
  font-size: 20px;
  margin-left: 5px;
}
.modalview_list .modalview_group > div > label p {
  margin: 0px;
  display: inline-block;
}

.accordion_summary div {
  flex-direction: row-reverse;
}

/* -----------snackbar CSS start------- */

.snackbarcls .css-zzms1-MuiSnackbar-root {
  top: 31%;
}
.loginFormBlock {
  margin-top: 75px;
}

@media (max-width: 620px) {
  .loginFormBlock {
    width: auto;
  }
  .loginFormBlock {
    flex: 1 1 auto;
    width: auto;
  }
  .linkText {
    position: inherit;
  }
}
@media (max-width: 580px) {
  .logoText {
    font-size: 24px;
  }
  .logoFormText {
    font-size: 20px;
  }
}

/* -----------snackbar CSS end------- */

@media screen and (max-width: 800px) {
  .custommodal .MuiBox-root {
    min-width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .reactTable {
    border: 0;
  }
  .reactTable caption {
    font-size: 1.3em;
  }
  .reactTable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .reactTable tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  .reactTable td {
    display: block;
    font-size: 14px;
    text-align: right;
  }
  .reactTable td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .reactTable td:last-child {
    border-bottom: 0;
  }
  .countDiv__column--col6.rTable {
    flex: 1 0 98% !important;
  }
}
/**************End Table and Title css*********************/

/**************Logged User Section*******************/
.loggedUser .css-pwngrv-MuiPaper-root-MuiCard-root.formCls {
  padding: 10px 20px !important;
  background: #000000 !important;
  border-radius: 20px !important;
  border: 3px solid #3d4144;
  box-shadow: none !important;
}
.loggedUser .formCls .formInline {
  padding: 0 !important;
}
.loggedUser .formCls .formInline .MuiIcon-root {
  padding: 2px;
  border: 2px solid #ffb640;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 100px;
}
.loggedUser .formCls .formInline p.formGroup {
  color: #fff;
  text-transform: uppercase;
}
/*****************************/

@media screen and (max-width: 1800px) {
  .pageWrapper {
    max-width: 96%;
  }
  .topMenuLists button {
    font-size: 17px;
    padding: 5px 30px;
  }
}
@media screen and (max-width: 1666px) {
  .topMenuLists .topMenuListsWrapper button {
    font-size: 16px;
    padding: 5px 24px;
  }
  .topRightMenu .goToHome {
    font-size: 16px;
    padding: 5px 24px;
  }
}
@media screen and (max-width: 1500px) {
  .topMenuLists .topMenuListsWrapper button {
    font-size: 18px;
    padding: 5px 20px;
  }
}
@media screen and (max-width: 1280px) {
  .topMenuLists .topMenuListsWrapper button {
    padding: 5px 13px;
  }
}
@media screen and (max-width: 1199px) {
  .topMenuListsWrapper {
    flex-wrap: wrap;
  }
  .topRightMenu {
    text-align: center;
  }
  .topLogo .loginFormLogo {
    margin: 0 auto;
  }
}
@media screen and (max-width: 991px) {
  .navbarToggle {
    display: block;
  }
  .topMenuLists {
    display: none;
  }
  .topMenuLists.addCSS {
    display: block;
  }
}
@media screen and (max-width: 599px) {
  .formCls .formInline .formGroup {
    flex: 0 0 100% !important;
    padding-left: 0;
  }
}
@media screen and (max-width: 400px) {
  .custommodal .MuiBox-root {
    min-width: 90%;
  }
}

/*New Modal*/
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.modal-content {
  width: 500px;
  max-height: 650px;
  background-color: #fff;
  border: solid 10px #08507f;
  border-radius: 4px;
  overflow-y: scroll;
  z-index: 1;
}

.modal-header,
.modal-footer {
  padding: 15px;
  text-align: center;
}

.modal-title {
  width: 100%;
  margin: 0;
  position: relative;
}

.modal-body {
  padding: 25px;
}

.modal-button {
  padding: 5px 10px;
  border-width: 0px;
  background-color: #0c3b69;
  color: #fff;
  position: absolute;
  top: 7.5%;
  right: 30.5%;
  cursor: pointer;
  font-size: large;
  border-radius: 61px;
}
.modal_slots {
  width: 95%;
  margin: auto;
  overflow-wrap: break-word;
}
.modal_slots p {
  margin-bottom: 0;
}
